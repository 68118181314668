<template>
    <div class="Message xi">
        <nh-com v-if="msgType == 0"></nh-com>
        <h-com v-if="msgType == 1"></h-com>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personalmesBox">
                    <div class="personal-left">
                        <div class="contentBoxTitle">消息通知</div>
                        <div :class="msgIndex == 0 ? 'contentBoxContent' : 'contentBoxContentno'"
                            style="position: relative;" @click="gomsgIndex(0)">系统消息 <div v-if="unreadCountXt > 0" class="mesTopItem1">{{
                                unreadCountXt }}</div>
                        </div>
                        <div :class="msgIndex == 3 ? 'contentBoxContent' : 'contentBoxContentno'"
                            style="position: relative;" @click="gomsgIndex(3)">支付消息 <div v-if="unreadCountZf > 0" class="mesTopItem1">{{
                                unreadCountZf }}</div>
                        </div>
                        <div :class="msgIndex == 6 ? 'contentBoxContent' : 'contentBoxContentno'"
                            style="position: relative;" @click="gomsgIndex(6)">门诊消息 <div v-if="unreadCountMz > 0" class="mesTopItem1">{{
                                unreadCountMz }}</div>
                        </div>
                        <!-- <div :class="msgIndex == 2 ? 'contentBoxContent' : 'contentBoxContentno'"
                            @click="gomsgIndex(2)">快递消息</div> -->
                        <div :class="msgIndex == 7 ? 'contentBoxContent' : 'contentBoxContentno'"
                            style="position: relative;" @click="gomsgIndex(7)">转诊消息 <div v-if="unreadCountZz > 0" class="mesTopItem1">{{
                                unreadCountZz }}</div>
                        </div>
                        <div :class="msgIndex == 10 ? 'contentBoxContent' : 'contentBoxContentno'"
                            style="position: relative;" @click="gomsgIndex10">平台消息 <div  class=""></div>
                        </div>
                    </div>
                    <div class="personal-right">
                        <div class="center-content">
                            <div class="contentBoxTitleR" v-if="msgIndex == 0">
                                系统消息
                            </div>
                            <div class="contentBoxTitleR" v-if="msgIndex == 6">
                                门诊消息
                            </div>
                            <div class="contentBoxTitleR" v-if="msgIndex == 3">
                                支付消息
                            </div>
                            <div class="contentBoxTitleR" v-if="msgIndex == 7">
                                转诊消息
                            </div>
                            <div class="contentBoxTitleR" v-if="msgIndex == 10">
                                平台消息
                            </div>
                            <div v-if="msgIndex == 10" class="contentBoxR">
                                <div class="contentBoxRL">图片</div>
                                <div class="contentBoxRL">标题</div>
                                <div class="contentBoxRL">发布时间</div>
                                <div class="contentBoxRL">操作</div>
                            </div>
                            <div v-else class="contentBoxR">
                                <div class="contentBoxRL">时间</div>
                                <div class="contentBoxRR">标题</div>
                            </div>
                            <div v-if="msgIndex == 10">
                                <div v-for="(MessageItem, i) in MessageListP" :key=i>
                                    <div :class="MessageItem.isRead ? 'mesTopItemRead' : 'contentBoxRCP'">
                                        <div class="item-imgP">
                                            <img v-if="MessageItem.imageUrl" :src="$basePath.Authorization + MessageItem.imageUrl" alt="" srcset="">
                                             <img v-else src="../assets/pingMsg.png" alt="">
                                        </div>
                                       <div class="contentBoxRLCP" >{{MessageItem.title}} </div>
                                         <div class="contentBoxRLCP">{{ MessageItem.createTime | timefilters }}</div>
                                         <div  class="contentBoxRLCP">
                                            <el-button size="mini" type="primary"  @click="goMessageDetailP(MessageItem.id)">详情</el-button>
                                         </div>
                                          
                                    </div>
                                </div>
                                <div class="contentNone" v-if="MessageListP.length == 0">暂无消息</div>
                                <div style="margin: 30px ;">
                                    <el-pagination @current-change="handleCurrentChangeP" :page-size="15"
                                        :current-page="PageIndexP" :total="MessageTotalP"
                                        layout="total, prev, pager, next, jumper"></el-pagination>
                                </div>
                            </div>
                            <div v-else>
                            <div v-for="(MessageItem, i) in MessageList" :key=i>
                                <div :class="MessageItem.isRead ? 'mesTopItemRead' : 'contentBoxRC'">
                                    <div class="contentBoxRLC">{{ MessageItem.createTime | timefilters }}</div>
                                    <div class="contentBoxRRC" @click="goMessageDetail(MessageItem.id)">
                                        <el-collapse v-model="activeNames" accordion>
                                            <el-collapse-item
                                                :title="MessageItem.typeName + ' ' + '|' + ' ' + (msgIndex == 3 ? MessageItem.showJumpOp.TransactionType : MessageItem.title ) "
                                                :name='i'>
                                                <div class="collapseCon">{{ MessageItem.content }}
                                                    <span v-if="MessageItem.subType == 24" class="goHandl"
                                                        @click="goHandle(MessageItem.subType)">查看</span>
                                                    <span v-if="MessageItem.subType == 25" class="goHandl"
                                                        @click="goHandle(MessageItem.subType)">去处理</span>
                                                </div>
                                                <div class="item-img"
                                                    v-if="msgIndex == 0 && MessageDetail.atta && MessageDetail.atta.length > 0">
                                                    <img v-for="(item, index) in MessageDetail.atta"
                                                        :src="$basePath.Authorization + item.filePath" :key="index"
                                                        @click="handleImagePreview(item)">
                                                </div>
                                                <el-dialog :visible.sync="Image.dialogVisible"
                                                    :modal-append-to-body="false">
                                                    <img width="100%"
                                                        :src="$basePath.Authorization + Image.dialogImageUrl" alt="">
                                                </el-dialog>
                                            </el-collapse-item>
                                        </el-collapse>
                                    </div>
                                </div>
                            </div>
                            <div class="contentNone" v-if="MessageList.length == 0">暂无消息</div>
                            <div style="margin: 30px ;">
                                <el-pagination @current-change="handleCurrentChange" :page-size="15"
                                    :current-page="PageIndex" :total="MessageTotal"
                                    layout="total, prev, pager, next, jumper"></el-pagination>
                            </div>
                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
import { MessageDomain } from '../components/personal/Message';
import ftCom from '../components/footer'
import nhCom from "../components/navheader";
import hCom from "../components/header";
import hdImg from "../components/headerImg";
var pageIndex = 1;
var keywordVal = ''
export default {
    name: "Message",
    components: {
        ftCom,
        nhCom,
        hdImg,
        hCom
    },
    data() {
        var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
        return {
            labelPosition: 'right',
            MessageList: [],
            MessageDetail: {},
            total: 0,
            currentPage: 1, //初始页
            keyword: '',
            PageIndex: 1,
            PageTotal: 1,
            MessageTotal: 0,
            rloading: [],//loading遮罩
            Result: MessageDomainTemp,
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            msgIndex: 0,
            msgType: this.$route.query.typeCom ? this.$route.query.typeCom : 0,
            activeNames: ['1'],
            homeMessage: [],
            unreadCountXt: 0,
            unreadCountZf: 0,
            unreadCountZz: 0,
            unreadCountMz:0,
            PageIndexP:1,
            PageTotalP:1,
            MessageTotalP:1,
            currentPageP: 1, //初始页
            MessageListP:[]
        };
    },
    mounted() {
        if (this.$route.query.msgIndex) {
            this.msgIndex = this.$route.query.msgIndex;
        }
        this.AllMessages();
    },
    methods: {
        getHomeMessage() {
            let _this = this;
            _this.Result.GetHomeMessage(function (data) {
                let readNumber = 0
                for (let i=0; i<data.data.length; i++) {
                readNumber = readNumber + data.data[i].unreadCount
                }
                if (readNumber > 0) {
                _this.$store.commit('messageData', true)
                } else {
                _this.$store.commit('messageData', false)
                }
                _this.homeMessage = data.data;
                _this.unreadCountXt = _this.homeMessage[0].unreadCount;
                _this.unreadCountZf = _this.homeMessage[2].unreadCount;
                _this.unreadCountMz = _this.homeMessage[5].unreadCount;
                _this.unreadCountZz = _this.homeMessage[6].unreadCount;
                if (_this.unreadCountXt == 0 && _this.unreadCountZf == 0 && _this.unreadCountZz == 0) {
                    _this.$bus.$emit('IsHomeMessage');
                }
            }, function (error) {
                console.log(error);
            })
        },
        goMessageDetailP(MessageItemId){
            var _this = this
            _this.$router.push({
                name:'messageDetail',
                query:{
                    id:MessageItemId,
                    msgType:_this.msgType
                }
            })
        },
        gomsgIndex10(e){
            var _this = this
            _this.msgIndex = 10
            _this.PageIndexP = 1;
            _this.MessageList = [];
            _this.Result.GetHomeMessageP( '%20',_this.PageIndexP, '15', function (data) {
                if (data.data) {
                    _this.PageIndexP = data.data.pageIndex;
                    _this.PageTotalP = data.data.pageCount;
                    _this.MessageTotalP = data.data.dataTotal;
                    _this.MessageListP = data.data.results;
                }
            },
                function (err) {
                    console.log(err);
                }
            )
        },
        goMessageDetail(id) {
            var _this = this
            _this.Result.MessageDetails(id, function (data) {
                _this.MessageDetail = data.data;
                _this.MessageDetail.showJumpOp = JSON.parse(data.data.jumpOp)
                _this.getHomeMessage();
                // _this.AllMessages();
            }, function (error) {
                console.log(error);
            })
        },
        gomsgIndex(e) {
            this.msgIndex = e
            this.PageIndex = 1;
            this.AllMessages()
        },
        goHandle(subType) {
            if (subType == 24) {
                this.$router.push({
                    path: '/interrogation/referralAndmedical',
                })
            } else if (subType == 25) {
                this.$router.push({
                    path: '/interrogation/referralAndmedical',
                })
            }

        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.filePath;
            this.Image.dialogVisible = true;
        },
        //返回列表页
        goBack() {
            $('.center-content').show();
            $('.personal-right-tit').show();
            $('.msg-detail').hide();

        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum;
            this.PageIndex = pageNum;
            this.AllMessages();
        },
        handleCurrentChangeP: function (pageNum) {
            this.currentPageP = pageNum;
            this.PageIndexP = pageNum;
            this.AllMessagesP();
        },
        toDetail(i) {
            var _this = this;
            $('.center-content').hide();
            $('.personal-right-tit').hide();
            $('.msg-detail').show();
            _this.Result.MessageDetails(_this.MessageList[i].id, function (data) {
                _this.MessageDetail = data.data;
            }, function (error) {
                console.log(error);
            })
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.AllMessages();
        },
        AllMessages() {
            // type 0 系统消息  2 快递消息  3 支付消息 （支出和入账）4 账号消息5 申请消息6 门诊消息 7 转诊消息
            var _this = this;
            _this.rloading = this.openLoading();
            _this.MessageList = [];
            _this.Result.AllMessage(_this.msgIndex, _this.PageIndex, '%20', function (data) {
                if (data.data) {
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.MessageTotal = data.data.dataTotal;
                    _this.MessageList = data.data.results;
                    if (_this.msgIndex == 3 ) {
                        for (let i=0; i<_this.MessageList.length; i++) {
                            _this.MessageList[i]['showJumpOp'] = JSON.parse(_this.MessageList[i].jumpOp)
                        }
                    }
                    // data.data.results.forEach(function (item, index) {
                    //     if (_this.MessageList[index].createTime) {
                    //         _this.MessageList[index].createTime = item.createTime.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').slice(0, item.createTime.indexOf("."));
                    //     }
                    //     switch (item.categoryId) {
                    //         case 1:
                    //             {
                    //                 _this.MessageList[index].Type = "系统消息"
                    //                 break;
                    //             }
                    //         case 2: {
                    //             _this.MessageList[index].Type = "机构消息"
                    //             break;
                    //         }
                    //         case 3: {
                    //             _this.MessageList[index].Type = "平台消息"
                    //             break;
                    //         }
                    //     }
                    // });
                    _this.getHomeMessage();
                }
            },
                function (err) {
                    console.log(err);
                }
            )
            _this.rloading.close()
        },
        AllMessagesP() {
            var _this = this;
            _this.MessageListP  = []
              _this.Result.GetHomeMessageP( '%20',_this.PageIndexP, '15', function (data) {
                if (data.data) {
                    _this.PageIndexP = data.data.pageIndex;
                    _this.PageTotalP = data.data.pageCount;
                    _this.MessageTotalP = data.data.dataTotal;
                    _this.MessageListP = data.data.results;
                }
            },
                function (err) {
                    console.log(err);
                }
            )
        },
    }
}
</script>

<style scoped>
@import "../assets/css/footer.css";

@import "../assets/css/footer.css";

#page {
    text-align: center;
    padding: 100px 0 30px;
}

.contentBoxR {
    background: #F5F7FA;
    padding: 15px 30px;
    margin: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.contentNone {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.contentBoxRC {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    /* align-items: center; */
}
.contentBoxRCP {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.mesTopItemRead {
    background: #ffffff;
    padding: 0 30px;
    margin-top: 10px;
    display: flex;
    color: #999999;
}

.mesTopItemRead .collapseCon {
    color: #999999;
}

.contentBoxRLC {
    width: 30%;
    height: 47px;
    line-height: 47px;
    padding-left: 30px;
}
.contentBoxRLCP {
    width: 30%;
    height: 47px;
    line-height: 47px;
}

.contentBoxRRC {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contentBoxRRC ::v-deep .el-collapse {
    width: 100% !important;
    border: none !important;
}

.contentBoxRRC ::v-deep .el-collapse .el-collapse-item__wrap {
    border: none !important;
}

.contentBoxRC .contentBoxRRC ::v-deep .el-collapse .el-collapse-item__header {
    border: none !important;
}

.mesTopItemRead .contentBoxRRC ::v-deep .el-collapse .el-collapse-item__header {
    border: none !important;
    color: #999999 !important;
}

.goHandl {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.contentBoxRL {
    width: 30%;
}

.contentBoxRR {
    width: 70%;
}

.banner {
    width: 100%;
    height: 160px;
    background: url("../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personalmesBox {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 740px;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    width: 20%;
    min-height: 740px;
    background: #FFFFFF;
    border-radius: 10px;
}

.personal-right {
    width: 75%;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 18px;
}

.msg-list {
    padding: 0 20px;
}

.msg-list .msg-item {
    padding: 8px 0;
    border-bottom: 1px dashed #e5e5e5;
    line-height: 40px;
    height: 40px;
}

.msg-list .msg-item h4 {
    color: #999999;
    font-weight: normal;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.msg-list .msg-item.noread h4 {
    color: #333333;
}

.msg-list .msg-item p {
    color: #999999;
}

.msg-detail {
    padding: 20px;
    display: none;
}

.msg-detail .mess-tit {
    text-align: center;
}

.msg-detail .mess-info p {
    text-indent: 28px;
}

.mess-info .item-img {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
}
.item-imgP {
    /* margin-right: 10px; */
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
    width: 20%;
     padding-right: 15px;
}
.item-imgP img {
     width: 100%;
    
    /* margin: 0 1px; */
}
.item-imgP div {
    width: 100%;
    /* margin: 0 1px; */
}

.item-img img {
    width: 118px;
    margin: 0 1px;
    /* height: 118px; */
}

.contentBoxTitle {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    padding: 30px;
    padding-bottom: 0;
    cursor: pointer;
}

.contentBoxTitleR {
    font-size: 16px;
    color: #333333;
    padding: 30px;
    padding-bottom: 0;
    cursor: pointer;
}

.mesTopItem1 {
    min-height: 25px;
    min-width: 25px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #EB1E1E;
    position: absolute;
    top: 5px;
    right: 5px;
}

.contentBoxContent {
    width: 150px;
    padding: 10px;
    text-align: center;
    background: #333333;
    border-radius: 6px;
    color: #FFFFFF;
    margin-left: 30px;
    margin-top: 16px;
    cursor: pointer;
}

.contentBoxContentno {
    width: 150px;
    padding: 10px;
    border: 1px solid #999999;
    text-align: center;
    background: #ffffff;
    border-radius: 6px;
    color: #999999;
    margin-left: 30px;
    margin-top: 16px;
    cursor: pointer;
}
</style>
